import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'patients',
    pathMatch: 'full'
  },
  {
    path: 'map',
    loadChildren: './modules/map/map.module#MapModule'
  },
  {
    path: ':type',
    loadChildren: './modules/portal/portal.module#PortalModule',
  },
  {
    path: '**',
    redirectTo: 'patients'
  }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);

import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs/index';
import { NavigationEnd } from '@angular/router';

@Injectable()
export class CommunicationService {

  private onRouteChange = new ReplaySubject<NavigationEnd>(1);
  private onActiveLocationsChange = new Subject<any>();
  private onDisplayLocationsChange = new Subject<any>();
  private onPortalConfigurationChange = new BehaviorSubject<any>({});

  routeChange$ = this.onRouteChange.asObservable();
  activeLocationsChange$ = this.onActiveLocationsChange.asObservable();
  displayLocationsChange$ = this.onDisplayLocationsChange.asObservable();
  portalConfigurationChange$ = this.onPortalConfigurationChange.asObservable();

  changeRoute(route: NavigationEnd) {
    this.onRouteChange.next(route);
  }

  changeActiveLocations(locations: any) {
    this.onActiveLocationsChange.next(locations);
  }

  changeDisplayLocations(locations: any) {
    this.onDisplayLocationsChange.next(locations);
  }

  changePortalConfiguration(configuration: any) {
    this.onPortalConfigurationChange.next(configuration);
  }
}

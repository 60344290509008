import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LOCALE_ID, NgModule } from '@angular/core';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorModule } from 'ngx-device-detector';
import localeMk from '@angular/common/locales/mk';
import { registerLocaleData } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CoreModule } from './core/core.module';
import { MatPaginatorIntl } from '@angular/material';
import { PaginatorI18n } from './utils/paginator-i18n.utils';
import { RECAPTCHA_BASE_URL, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';

export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, `./assets/i18n/`, '.json');
}

const translationOptions = {
  loader: {
    provide: TranslateLoader,
    useFactory: (HttpLoaderFactory),
    deps: [HttpClient]
  }
};

const modules = [
  CoreModule,
  routing,
  DeviceDetectorModule.forRoot(),
  TranslateModule.forRoot(translationOptions)
];

const services = [
  {provide: LOCALE_ID, useValue: 'mk'},
  {provide: MatPaginatorIntl,
    deps: [TranslateService],
    useFactory: (translateService: TranslateService)=> new PaginatorI18n(translateService).getPaginatorIntl()
  },
  {
    provide: RECAPTCHA_BASE_URL,
    useValue: 'https://www.google.com/recaptcha/api.js'
  }
];

registerLocaleData(localeMk, 'mk');

@NgModule({
  declarations: [AppComponent],
  imports: [...modules],
  providers: [...services],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private translate: TranslateService) {
    translate.addLangs(['mk']);
    translate.use('mk');
  }
}

import { NgModule } from '@angular/core';
import { SiteService } from './services/site.service';
import { CommunicationService } from './services/communication.service';
import { DeviceService } from './services/device.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';


const services = [
  CommunicationService,
  DeviceService,
  SiteService
];

const modules = [
  CommonModule,
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  FormsModule,
];

@NgModule({
  declarations: [],
  imports: [...modules],
  providers: [...services],
})
export class CoreModule { }

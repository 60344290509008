import { Injectable } from '@angular/core';
import { Observable } from "rxjs/index";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SiteService {

  private api: string = 'api/pp';

  constructor(private http: HttpClient) {
  }

  public getSiteConfiguration(): Observable<any> {
    return this.http.get<any>(`${this.api}/site_configuration`);
  }

  public  getPortalConfiguration(): Observable<any> {
    return this.http.get<any>(`${this.api}/portal_configuration`);
  }

  public getGoogleConfiguration(): Observable<any> {
    return this.http.get<any>(`${this.api}/google_recaptcha_config`);
  }
}

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommunicationService } from './core/services/communication.service';
import { Title } from '@angular/platform-browser';
import { SiteService } from './core/services/site.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router,
    private communicationService: CommunicationService,
    private siteService: SiteService,
    private titleService: Title) {
  }

  ngOnInit() {
    this.siteService.getSiteConfiguration()
      .subscribe(config => {
        this.titleService.setTitle(config.title);
        (<any>window).ga('create', config.gaTrackingId, 'auto');

        this.router.events.subscribe((val) => {
          if (val instanceof NavigationEnd) {
            this.communicationService.changeRoute(val);

            // Send page hit to google analytics
            (<any>window).ga('set', 'page', val.urlAfterRedirects);
            (<any>window).ga('send', 'pageview');
          }
        });
      });

    this.getPortalConfiguration();
  }

  getPortalConfiguration() {
    this.siteService.getPortalConfiguration().subscribe(config => {
      this.communicationService.changePortalConfiguration(config);
    });
  }

}

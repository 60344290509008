import { DeviceDetectorService } from 'ngx-device-detector';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DeviceService {

  constructor(private deviceService: DeviceDetectorService) { }

  /** Sometimes on Linux the device is set to unknown */
  get device(): string {
    return this.deviceService.device === 'unknown' ? 'desktop' : this.deviceService.device;
  }

  get browser(): string {
    return this.deviceService.browser;
  }

  get isDesktop(): boolean {
    return this.device === 'desktop';
  }

  get isIE(): boolean {
    return this.browser === 'ie';
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }
}
